<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
  size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
  body {
    font-size: 12px;
  }
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 2px solid #000000;
  text-align: left;
  padding: 8px;
}
#title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.remove_border td {
  border: none;
}
#logo {
  position: absolute;
  width: 200px;
  height: 100px;
}
#table-list {
  margin-top: 80px;
}
#info {
  margin-top: 20px;
  width: 40%;
  float: right;
  margin-bottom: 20px;
}
</style>
<template>
  <!-- print onload -->
  <div>
    <img :src="logoSDN" id="logo" />
    <p id="title">Rekapan Hasil Penagihan (RHP)</p>
    <div id="info" v-show="showInfo">
      <table>
        <tr v-show="parent.no_dafin != ''">
          <th>Code</th>
          <th>:</th>
          <th>{{ parent.no_dafin }}</th>
        </tr>
        <tr>
          <th>Branch</th>
          <th>:</th>
          <th>{{ parent.branch }}</th>
        </tr>
        <tr v-show="parent.collector != ''">
          <th>Sales / Collector</th>
          <th>:</th>
          <th>{{ parent.collector }}</th>
        </tr>
        <tr>
          <th>Tanggal RHP</th>
          <th>:</th>
          <th>{{ this.date_str }}</th>
        </tr>
      </table>
    </div>
    <table id="table-list">
      <thead>
        <tr>
          <th style="text-align: center" colspan="7">
            Penyerahan DAFIN untuk ditagih
          </th>
          <th style="text-align: center" colspan="11">
            Hasil Penagihan & Serah Terima
          </th>
        </tr>
        <tr></tr>
        <tr>
          <th>No</th>
          <th>Sales Name</th>
          <th>Kode Customer</th>
          <th>Nama Pelanggan</th>
          <th>Tgl Billing</th>
          <th>Tgl JT Tempo / Tgl Pengiriman</th>
          <th>No Dokumen</th>
          <th>Nilai Tagihan</th>
          <th>Tunai</th>
          <th>Transfer</th>
          <th>Nominal Giro</th>
          <th>Nama Bank</th>
          <th>Tgl JT Tempo</th>
          <th>No. BG</th>
          <th>Nama Bank Giro</th>
          <th>Potongan Promo / Retur Dll</th>
          <th>Total Pembayaran</th>
          <th>Penjelasan Faktur tidak tertagih (Keterangan)</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(v, k) in dafins" :key="k">
          <td>{{ k + 1 }}</td>
          <td>{{ v.collector_name }}</td>
          <td>{{ v.customer_code }}</td>
          <td>{{ v.customer_name }}</td>
          <td>{{ formatDate(v.billing_date) }}</td>
          <td>
            {{
              formatDate(v.due_date) + "/" + formatDate(v.delivery_order_date)
            }}
          </td>
          <td>{{ v.no_document }}</td>
          <td>{{ formatNumber(v.bill_outstanding) }}</td>
          <td>{{ formatNumber(v.cash_value) }}</td>
          <td>{{ formatNumber(v.transfer_value) }}</td>
          <td>{{ formatNumber(v.giro_value) }}</td>
          <td>{{ v.bank_name }}</td>
          <td>{{ v.date_giro }}</td>
          <td>{{ v.no_bg }}</td>
          <td>{{ v.bank_giro_name }}</td>
          <td>{{ formatNumber(v.deduction_value) }}</td>
          <td>
            {{ formatNumber(v.transfer_value + v.giro_value + v.cash_value) }}
          </td>
          <td>{{ v.reason }}</td>
        </tr>
        <tr>
          <td colspan="7">Total</td>
          <td>{{ formatNumber(bill_total) }}</td>
          <td>{{ formatNumber(cash_total) }}</td>
          <td>{{ formatNumber(transfer_total) }}</td>
          <td>{{ formatNumber(giro_total) }}</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ formatNumber(discon_total) }}</td>
          <td>{{ formatNumber(payment_total) }}</td>
          <td></td>
        </tr>
        <tr>
          &nbsp;
        </tr>
        <tr>
          &nbsp;
        </tr>
        <tr class="remove_border">
          <td colspan="2"></td>
          <td>Diserahkan (Faktur)</td>
          <td colspan="1"></td>
          <td>Diperiksa</td>
          <td colspan="1"></td>
          <td>Diterima (Faktur)</td>
          <td colspan="1"></td>
          <td>Diserahkan</td>
          <td colspan="1"></td>
          <td>Diterima (Hasil Tagihan)</td>
          <td colspan="1"></td>
          <td>Diterima (Faktur Kembali)</td>
          <td colspan="1"></td>
          <td>Diketahui</td>
        </tr>
        <tr>
          &nbsp;
        </tr>
        <tr>
          &nbsp;
        </tr>
        <tr>
          &nbsp;
        </tr>
        <tr>
          &nbsp;
        </tr>
        <tr class="remove_border">
          <td colspan="2"></td>
          <td>(Admin AR)</td>
          <td colspan="1"></td>
          <td>BAS</td>
          <td colspan="1"></td>
          <td>ASM/ASS</td>
          <td colspan="1"></td>
          <td>Kolektor / Sales</td>
          <td colspan="1"></td>
          <td>Kasir</td>
          <td colspan="1"></td>
          <td>(Admin AR)</td>
          <td colspan="1"></td>
          <td>BAS</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { formatNumber, isArray } from "../../../utils/common";
import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
  name: "PrintPdf",
  data() {
    return {
      dafins: [],
      parent: null,
      bill_total: 0,
      transfer_total: 0,
      cash_total: 0,
      giro_total: 0,
      discon_total: 0,
      payment_total: 0,
      date_str:"",
      logoSDN: logoSDN,
      showInfo: false,
    };
  },
  mounted() {
    this.print();
  },
  methods: {
    async print() {
      // show info
      const qShowInfo = this.$route.query.show_info;
      if (qShowInfo) {
        this.showInfo = true;
      }
      let ids = this.$route.query.collection_plan_ids;
      await this.getData(ids);
      //   print all
      await window.print();
    },
    async getData(ids) {
      let params = null;
      if (!isArray(ids)) {
        let newArray = [];
        newArray.push(ids);
        params = {
          collection_plan_ids: newArray,
        };
      } else {
        params = {
          collection_plan_ids: ids,
        };
      }

      let response = await this.$http.get(
        `/api/sfa/v1/collection-plan/multiple-export`,
        { params }
      );
      if (response.code == 200) {
        this.parent = response.data;
        if (this.parent.start_date == this.parent.end_date) {
          this.date_str = this.formatDate(this.parent.start_date);
        } else {
          this.date_str = this.formatDate(this.parent.start_date) + " s/d " + this.formatDate(this.parent.end_date);
        }
        this.dafins = response.data.dafins;
        // let collections = this.$route.query.collection_plan_ids;
        // check is array

        this.showInfo = true;

        for (let index = 0; index < response.data.dafins.length; index++) {
          this.cash_total += response.data.dafins[index].cash_value;
          this.transfer_total += response.data.dafins[index].transfer_value;
          this.giro_total += response.data.dafins[index].giro_value;
          this.discon_total += response.data.dafins[index].deduction_value;
          this.payment_total +=
            response.data.dafins[index].cash_value +
            response.data.dafins[index].transfer_value +
            response.data.dafins[index].giro_value;

          this.bill_total += response.data.dafins[index].bill_outstanding;
        }
      }
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      }
      return "";
    },
    formatNumber(value) {
      if (value) {
        return formatNumber(value);
      }
      return "";
    },
  },
};
</script>
